import * as React from 'react';
import Layout from '../../components/common/Layout';
import MainContents from '../../components/common/MainContents';
import PageHeading from '../../components/ui/Pageheading';
import { Button } from '../../components/ui/Button';

const DownloadThanksPage = () => (
    <Layout pageTitle="ご紹介資料 ダウンロード | FDM フードデリバリーマネージャー">
      <MainContents>
        <PageHeading>ご紹介資料 ダウンロード</PageHeading>
        <p className="mt-7.5 md:mt-20 text-sm font-medium tracking-wide leading-loose text-center">
          この度は、ご利用いただきまして<br />誠にありがとうございます。
        </p>
        <p className="mt-7.5 max-w-lg mx-auto md:px-1.5 text-sm tracking-wide leading-loose">
          フードデリバリーマネージャーのご導入にあたり、ご相談・お問い合わせは無料で承っております。お気軽にご連絡ください。
        </p>
        <div className="mt-10 mx-auto md:pb-40 max-w-3xs text-center">
          <Button color="gray" size="xs" href="/" className="w-full">TOPに戻る</Button>
        </div>
      </MainContents>
    </Layout>
  );

export default DownloadThanksPage;
